import "./Card.scss";
import { Card } from "react-bootstrap";

const CustomCard = (props) => {
  return (
    <Card className="custom-card">
      <Card.Img
        loading="lazy"
        className="img-fluid"
        variant="top"
        src={props.img}
        alt={props.altText}
      />
      <Card.Body>
        <Card.Title> {props.title} </Card.Title>
        <Card.Text> {props.description} </Card.Text>
        <div className="price">{props.price}€</div>
      </Card.Body>
      <a
        aria-label="Read more about this product"
        className="stretched-link"
        href={props.link}
      ></a>
    </Card>
  );
};

export default CustomCard;
