import Images from "./Images";

const textToLink = (text) => {
  return text.split(" ").join("-").toLowerCase();
};

// 3d panels
const PanelsData = [
  {
    img: Images.marblePanelGrey,
    title: "Grey",
    subTitle: '3d paneli',
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "35.00",
    altText: "Mermerni panel grey",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelNatura,
    title: "Natura",
    subTitle: '3d paneli',
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "35.00",
    altText: "Mermerni panel natura",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelWhite,
    title: "White",
    subTitle: '3d paneli',
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "35.00",
    altText: "Mermerni panel white",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelWoodBlack,
    title: "Wood and black",
    subTitle: '3d paneli',
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "35.00",
    altText: "Mermerni panel wood and black",
    get link() {
      return textToLink(this.title);
    },
  },
];

// marble panels
const FlexPanelsData = [
  {
    img: Images.marblePanelBlue,
    title: "Blue",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Fleksibilni paneli sa završnicom imitacije mermera predstavljaju savršeno rešenje za one koji žele da svojim enterijerom unesu eleganciju i luksuz po pristupačnoj ceni. Ovi paneli se izrađuju od pvc materijala koji je fleksibilan i lagan, što omogućava da se lako instaliraju na zidove, plafone, stubove i druge površine.

    Završnica na fleksibilnim panelima imitira prirodni mermer u svakom detalju, što daje vrhunski luksuzan izgled prostoru, ali bez troškova i težine pravog kamena. Ovi paneli dolaze u raznim bojama i uzorcima, tako da se mogu savršeno uklopiti u bilo koji enterijer i stvoriti impresivan vizuelni efekat.
    
    Jedna od ključnih prednosti fleksibilnih panela je njihova izdržljivost. Materijal od kojeg su izrađeni je otporan na habanje, ogrebotine i lomove, što ih čini idealnim za kućne i komercijalne prostore sa visokim stepenom prometa.
    
    Osim svoje estetske privlačnosti, fleksibilni paneli sa završnicom imitacije mermera su i ekološki prihvatljiv izbor. Korišćenjem ovih panela, izbegavaju se troškovi i problemi koji su povezani sa korišćenjem prirodnog kamena, poput eksploatacije i transporta.
    
    Ukratko, fleksibilni paneli sa završnicom imitacije mermera su savršeno rešenje za one koji traže elegantan, luksuzan i izdržljiv enterijer po pristupačnoj ceni. Sa velikim izborom boja  i uzoraka, ovi paneli mogu uneti vrhunsku estetiku u svaki prostor."
    
     `,
    price: "80.00",
    altText: "Mermerni panel blue",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelCalca,
    title: "Calca",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel calca",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelCrema,
    title: "Crema",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel crema",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelEmperador,
    title: "Emperador",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel emperador",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelLightGrey,
    title: "Light grey",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel light grey",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelPietraGrey,
    title: "Pietra grey",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel pietra gray",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelTraventino,
    title: "Traventino",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel traventino",
    get link() {
      return textToLink(this.title);
    },
  },
  {
    img: Images.marblePanelWood,
    title: "Wood",
    subTitle: "flex panel",
    descr: "122 x 280cm",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "80.00",
    altText: "Mermerni panel wood",
    get link() {
      return textToLink(this.title);
    },
  },
];

// floor panels
const FloorPanelsData = [
  {
    img: Images.cardImgFloor,
    title: "Podni paneli",
    descr: "Lorem ipsum",
    postDescr: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat veniam culpa ipsum ipsam dolores fugiat enim tempora esse non totam in similique temporibus molestias, odio qui tempore eveniet praesentium doloribus fuga dolorum consequuntur sint eos! Eaque, culpa similique. Tempora placeat fugit hic commodi fuga praesentium quae eius, tenetur sequi mollitia amet facilis! Iusto vero praesentium voluptates repellendus dolor ab, tenetur soluta eaque ullam? Sunt assumenda quasi explicabo qui accusantium quas sint molestias minima accusamus iste? Alias quis in ullam possimus omnis, voluptates itaque consequatur illum nobis saepe maxime iure eos veritatis nemo molestias. Vitae corrupti explicabo error iste, tenetur beatae corporis cupiditate maxime ad quibusdam minima quam impedit autem iusto voluptates, atque debitis placeat, ipsa doloribus. Itaque iste a corrupti recusandae necessitatibus deserunt nulla est incidunt. Inventore ex molestiae tenetur? Odit, fuga. Natus accusamus temporibus corrupti. Accusamus commodi deleniti ex error. `,
    price: "100.00",
    altText: "Podni paneli",
    get link() {
      return textToLink(this.title);
    },
  },
];

export { PanelsData, FlexPanelsData, FloorPanelsData };
