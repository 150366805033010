import { Row, Col } from "react-bootstrap";
import "./ProductContactForm.scss";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const ProductContactForm = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9l1rldo",
        "template_sw99nk4",
        form.current,
        "vIjlcecW1gHfCeOG6"
      )
      .then(e.target.reset());
  };
  return (
    <form className="product-contact" ref={form} onSubmit={sendEmail}>
      <Row className="g-3">
        <Col md={8} xs={12}>
          <input
            type="text"
            name="user_product"
            defaultValue={`${props.title} - ${props.subTitle}`}
          />
        </Col>
        <Col md={4} xs={12}>
          <input
            type="text"
            name="user_kolicina"
            placeholder="Kolicina"
            defaultValue={props.quantity}
          />
        </Col>
        <Col md={8} xs={12}>
          <input required type="email" name="user_email" placeholder="E-mail" />
        </Col>
        <Col md={4} xs={12}>
          <input
            required
            type="tel"
            name="user_phone"
            placeholder="Broj telefona"
          />
        </Col>
        <Col xs={12}>
          <textarea required name="message" placeholder="Dodatna pitanja" />
        </Col>
        <Col md={6} xs={12} className="d-flex justify-content-end">
          <input
            className="btn modal-btn"
            type="submit"
            value="narucite online"
          />
        </Col>
        <Col md={6} xs={12} className="d-flex justify-content-start">
          <a
            className="btn modal-btn d-flex justify-content-center align-items-center"
            href="tel:069934000"
          >
            pozovite nas
          </a>
        </Col>
      </Row>
    </form>
  );
};

export default ProductContactForm;
