import "./Hero.scss";

const Hero = (props) => {
  return (
    <section className="hero hero-container">
      <img src={props.img} alt={props.altText} />
      <div className="hero-content">
        <h2>{props.heading}</h2>
      </div>
    </section>
  );
};

export default Hero;
