import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/fonts/Raleway-VariableFont_wght.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);
