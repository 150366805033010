import "./Contact.scss";
import Hero from "../../components/layout/hero/Hero";
import Images from "../../Images";
import { Row, Container } from "react-bootstrap";
import Tag from "../../components/UI/Tag/Tag";
import ContactForm from "../../components/layout/contact-form/ContactForm";

const Contact = () => {
  return (
    <>
      <Hero
        img={Images.contactHeroImg}
        altText="contact us"
        heading="contact us"
      />

      {/* contact tags */}
      <section>
        <Container className="mt-5 tag-container">
          <Row>
            <Tag
              icon="bi bi-geo-alt-fill"
              content="Nova Dalmatinska br. 15"
              href="/"
            />
            <Tag
              icon="bi bi-envelope"
              content="flexdecorme@gmail.com"
              href="mailto:flexdecorme@gmail.com"
            />
            <Tag
              icon="bi bi-telephone-fill"
              content="+392 67 980 680"
              href="tel:067980680"
            />
          </Row>
        </Container>
      </section>

      {/* contact form */}
      <section>
        <Container className="my-5">
          <ContactForm />
        </Container>
      </section>
    </>
  );
};

export default Contact;
