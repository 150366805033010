import { Route, Routes } from "react-router-dom";
import Home from "../../../pages/home-page/Home";
import About from "../../../pages/about-page/About";
import Contact from "../../../pages/contact-page/Contact";
import Panels3d from "../../../pages/products-pages/panels-3d/Panels3d";
import Post from "../../../pages/post-pages/Post";
import Floors from "../../../pages/products-pages/floors/Floors";
import PanelsFlex from "../../../pages/products-pages/flex-panels/FlexPanels";
import NotFound from "../../../pages/404/404";
import {
  PanelsData,
  FlexPanelsData,
  FloorPanelsData,
} from "../../../products-data";

const NavRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="products/3d-panels" element={<Panels3d />} />
      {PanelsData.map((items, key) => {
        return (
          <Route
            key={key}
            path={`products/3d-panels/${items.link}`}
            element={
              <Post
                title={items.title}
                subTitle={items.subTitle}
                description={items.postDescr}
                price={items.price}
              />
            }
          />
        );
      })}
      <Route path="products/flex-panels" element={<PanelsFlex />} />
      {FlexPanelsData.map((items, key) => {
        return (
          <Route
            key={key}
            path={`products/3d-panels/${items.link}`}
            element={
              <Post
                title={items.title}
                description={items.postDescr}
                price={items.price}
                subTitle={items.subTitle}
              />
            }
          />
        );
      })}
      <Route path="products/floors" element={<Floors />} />
      {FloorPanelsData.map((items, key) => {
        return (
          <Route
            key={key}
            path={`products/3d-panels/${items.link}`}
            element={
              <Post
                title={items.title}
                description={items.postDescr}
                price={items.price}
              />
            }
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavRoutes;
