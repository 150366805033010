import "./ContactForm.scss";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Row, Col } from "react-bootstrap";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9l1rldo",
        "template_f91892u",
        form.current,
        "vIjlcecW1gHfCeOG6"
      )
      .then(
        e.target.reset()
      );
  };
  return (
    <form className="contact-form" ref={form} onSubmit={sendEmail}>
      <Row>
        <Col md={6} xs={12}>
          <input type="text" name="user_name" placeholder="Your Name" />
          <input type="email" name="user_email" placeholder="Email..." />
          <input
            className="mb-md-0"
            type="tel"
            name="user_number"
            placeholder="Phone"
          />
        </Col>
        <Col md={6} xs={12}>
          <textarea name="message" placeholder="Message" />
        </Col>
        <Col md={12} xs={12} className="text-center mt-5">
          <input
            type="submit"
            value="Send"
            className="default-btn submit-btn"
          />
        </Col>
      </Row>
    </form>
  );
};

export default ContactForm;
