import { Modal, Button, Row, Col } from "react-bootstrap";
import ProductContactForm from "../../layout/contact-form/ProductContactForm";
import Logo from "../../../assets/img/flex-company-logo-alt.png";
import "./CustomModal.scss";

const CustomModal = (props) => {
  return (
    <Modal
      className="custom-modal"
      show={props.show}
      onHide={props.close}
      centered
    >
      <Row>
        <Col className="d-flex justify-content-center">
          <img src={Logo} alt="company logo" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <p>
            <span>Popunite formular</span> kako biste{" "}
            <span>naručili online</span> ili napravite porudžbinu{" "}
            <span>pozivom</span> i kontaktiraćemo Vas u najkraćem mogućem roku.
          </p>
        </Col>
      </Row>
      <ProductContactForm
        title={props.title}
        quantity={props.quantity}
        subTitle={props.subTitle}
      />

      <Button className="btn modal-btn-close" onClick={props.click}>
        <i className="bi bi-x" />
      </Button>
    </Modal>
  );
};

export default CustomModal;
