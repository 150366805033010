import Hero from "../../../components/layout/hero/Hero";
import Images from "../../../Images";
import { Container } from "react-bootstrap";
import CustomCard from "../../../components/UI/Card/Card";
import "./FlexPanels.scss";
import { FlexPanelsData } from "../../../products-data";

const FlexPanels = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <section>
        <Hero
          img={Images.heroImgFlex}
          altText="Flex Panels"
          heading="Flex Panels"
        />
      </section>

      <section>
        <Container className="my-5">
          <div className="card-container">
            {FlexPanelsData.map((items, id) => {
              return (
                <article key={id}>
                  <CustomCard
                    img={items.img}
                    altText={items.altText}
                    title={items.title}
                    size={items.descr}
                    price={items.price}
                    link={`/products/3d-panels/${items.link}`}
                  />
                </article>
              );
            })}
          </div>
        </Container>
      </section>
    </>
  );
};

export default FlexPanels;
