import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./TabCustom.scss";
import { Row, Col } from "react-bootstrap";
import Images from "../../../Images";

function TabCustom() {
  return (
    <Tabs
      defaultActiveKey="dostava"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="dostava" title="DOSTAVA">
        <Row className="align-items-center mt-4">
          <Col md={6} xs={12}>
            <p className="mb-3 text-uppercase fs-4">
              dostava za sve gradove crne gore
            </p>
            <p>
              Prosječno vrijeme dostave:
              <span className="color-black fw-bold"> 1-2 radna dana</span>
            </p>
            <p>
              Način plaćanja:
              <span className="color-black fw-bold"> Po uzećem</span>
            </p>
          </Col>
          <Col
            md={6}
            xs={12}
            className="d-flex justify-content-md-end justify-content-center"
          >
            <img className="img-fluid mt-md-0 mt-4" src={Images.boxImg} alt="boxes" />
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="rate" title="KUPOVINA NA RATE">
        <Row className="mt-4">
          <Col xs={12}>
            <p className="text-uppercase fs-4 mb-3">kupujte na rate</p>
          </Col>
          <Col className="text-center" xs={12} md={4}>
            <img className="img-fluid" src={Images.card} alt="credit card" />
            <p className="color-black">Hipotekarna banka</p>
            <p className="color-black fw-normal">kupovina od 2-24 rate</p>
          </Col>
          <Col className="text-center my-md-0 my-4" xs={12} md={4}>
            <img className="img-fluid" src={Images.card_2} alt="credit card" />
            <p className="color-black">CKB Shopping kartica</p>
            <p className="color-black fw-normal">kupovina od 2-12 rate</p>
          </Col>
          <Col className="text-center" xs={12} md={4}>
            <img className="img-fluid" src={Images.card} alt="credit card" />
            <p className="color-black">NLB kartica</p>
            <p className="color-black fw-normal">kupovina od 2-12 rate</p>
          </Col>
        </Row>
      </Tab>
    </Tabs>
  );
}

export default TabCustom;
