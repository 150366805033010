import "./Post.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useState } from "react";
import CustomModal from "../../components/UI/Modal/CustomModal";
// import Images from "../../Images";
import TabCustom from "../../components/UI/Tab/TabCustom";

const Post = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(1);

  const handleChange = (event) => {
    setCounter(event.target.value);
  };

  // increase counter
  const increase = () => {
    setCounter((count) => count + 1);
  };

  // decrease counter
  const decrease = () => {
    if (counter > 1) {
      setCounter((count) => count - 1);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="background" />
      <Container>
        <div className="post-container">
          <Row>
            <Col lg={5} xs={12} className="mb-5 me-lg-5 me-0">
              <Swiper
                loop={true}
                spaceBetween={10}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Thumbs]}
                className="mySwiper2 mb-3"
              >
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-1.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-2.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-3.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-4.jpg"
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                loop={true}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-1.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-2.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-3.jpg"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    alt="test"
                    src="https://swiperjs.com/demos/images/nature-4.jpg"
                  />
                </SwiperSlide>
              </Swiper>
            </Col>
            <Col className="d-flex flex-column gap-5" lg={5} xs={12}>
              <Row>
                <Col xs={12}>
                  <h2 className="pb-md-0 pb-2">{props.title}</h2>
                </Col>
              </Row>
              <Row>
                <Col md={7} className="d-flex flex-column justify-content-end">
                  <p className="fw-bold">
                    <span>€{props.price}</span>/ cijena po panelu
                  </p>
                  <p>Dimenzija panela: 200x100 </p>
                </Col>
                <Col md={5} className="d-flex flex-column mt-md-0 mt-3">
                  <p className="color-brown">Količina</p>
                  <div className="d-flex quantity-box">
                    <i
                      className="bi bi-dash d-grid color-main"
                      onClick={decrease}
                    />
                    <input
                      className="mx-2 color-main"
                      type="number"
                      onChange={handleChange}
                      value={counter}
                    />

                    <i
                      className="bi bi-plus d-grid color-main"
                      onClick={increase}
                    />
                  </div>
                </Col>
                <Col xs={12} className="mt-3">
                  <p>
                    {props.descr}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Modi laboriosam quibusdam consequuntur vitae provident quas
                    nam alias ullam cum error asperiores rerum, nesciunt
                    consequatur sapiente? Voluptas eveniet impedit nisi
                    possimus?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={5}>
                  <p className="color-brown">Cijena ukupno</p>
                  <p className="fs-2">€{props.price * counter}</p>
                </Col>
                <Col
                  md={6}
                  xs={7}
                  className="d-flex justify-content-end align-items-end"
                >
                  <button onClick={handleShow} className="btn">
                    <i className="bi bi-bag me-2" />
                    Naruči odmah
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TabCustom />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <CustomModal
        subTitle={props.subTitle}
        quantity={counter}
        title={props.title}
        show={show}
        close={handleClose}
        click={handleClose}
      />
    </>
  );
};

export default Post;
