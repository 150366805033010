import "./Divider.scss";

const Divider = (props) => {
  return (
    <div className="divider d-flex justify-content-center align-items-center">
      <div className="h3">{props.dividerContent}</div>
    </div>
  );
};

export default Divider;
