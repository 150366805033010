import "./404.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container fluid className="not-found-container">
      <Container className="text-center">
        <div className="not-found-mark color-main">404</div>
        <p>Stranica nije pronadjena...</p>
        <Link to="/">
          <button className="btn btn-primary rounded-0 border border-0 mt-2">
            Nazad na početnu
          </button>
        </Link>
      </Container>
    </Container>
  );
};

export default NotFound;
