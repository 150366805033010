import "./TeamCard.scss";
import { Card } from "react-bootstrap";

const TeamCard = (props) => {
  return (
    <Card className="team-card">
      <Card.Img className="img-fluid" variant="top" src={props.img} />
      <Card.Body>
        <Card.Title> {props.name} </Card.Title>
        <Card.Text> {props.title} </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TeamCard;
