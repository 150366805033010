// layout
import MainNav from "./components/layout/navbar/Navbar";
import Footer from "./components/layout/footer/Footer";

// routes
import NavRoutes from "./components/UI/NavRoutes/NavRoutes";

const App = () => {
  return (
    <div className="App">
      <MainNav />
      <main>
        <NavRoutes />
      </main>
      <Footer />
    </div>
  );
};

export default App;
