import "./Footer.scss";
import { Container, Row, Col } from "react-bootstrap";
import Images from "../../../Images";

const Footer = () => {
  return (
    <footer className="text-center">
      <Container>
        <Row>
          <Col>
            <p>Nova Dalmatinska br. 15</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="tel:+37267980680">+382 67 980 680</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href="mailto:flexdecorme@gmail.com">flexdecorme@gmail.com</a>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_1} />
          </Col>
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_2} />
          </Col>
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_3} />
          </Col>
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_1} />
          </Col>
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_2} />
          </Col>
          <Col>
            <img alt="partner company logo" src={Images.partnerLogo_3} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
