import { Button } from "react-bootstrap";
import "./DefaultButton.scss";

const DefaultButton = (props) => {
  return (
    <Button variant="primary" href={props.link} className="defaultBtn">
      {props.btnContent} <i className="bi bi-arrow-right" />
    </Button>
  );
};

export default DefaultButton;
