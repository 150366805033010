import "./Navbar.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/img/flex-company-logo.png";
import { useState } from "react";

const MainNav = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {["false"].map((expand) => (
        <Navbar key={expand} expand={expand} expanded={isOpen}>
          <Container>
            <NavLink to="/">
              <img
                src={Logo}
                className="d-inline-block align-top img-fluid"
                alt="Flex company logo"
              />
            </NavLink>
            <Navbar.Toggle
              onClick={() => setOpen(isOpen ? false : "expanded")}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header>
                <Navbar.Brand href="/" onClick={() => setOpen(false)}>
                  <img
                    src={Logo}
                    className="d-inline-block align-top img-fluid"
                    alt="Flex company logo"
                  />
                </Navbar.Brand>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavLink to="/" onClick={() => setOpen(false)}>
                    Home
                  </NavLink>
                  <NavDropdown
                    title="Products"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavLink
                      to="/products/3d-panels"
                      onClick={() => setOpen(false)}
                    >
                      3D panels
                    </NavLink>
                    <NavLink
                      to="/products/flex-panels"
                      onClick={() => setOpen(false)}
                    >
                      Flex panels
                    </NavLink>
                    <NavLink
                      to="/products/floors"
                      onClick={() => setOpen(false)}
                    >
                      Floors
                    </NavLink>
                  </NavDropdown>

                  <NavLink onClick={() => setOpen(false)} to="contact">
                    Contact
                  </NavLink>
                  <NavLink to="about" onClick={() => setOpen(false)}>
                    About us
                  </NavLink>
                </Nav>
              </Offcanvas.Body>
              <div className="offcanvas-footer p-3 d-flex justify-content-center">
                <a
                  aria-label="Our instagram page link"
                  href="https://www.instagram.com/flexdecor_me/"
                >
                  <i className="bi bi-instagram fs-3" />
                </a>
              </div>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default MainNav;
