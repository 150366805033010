import { Col } from "react-bootstrap";
import "./Tag.scss";

const Tag = (props) => {
  return (
    <Col
      md={4}
      xs={12}
      className="tag d-flex text-center flex-column align-items-center my-md-0 my-2"
    >
      <i className={props.icon} />
      <a href={props.href}> {props.content} </a>
    </Col>
  );
};

export default Tag;
