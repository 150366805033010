import './ReactVideoPlayer.scss'
import Images from "../../../Images";
import placeHolder from "../../../assets/videos/placeholder.mp4";

const ReactVideoPlayer = () => {
  return (
    <video poster={Images.videoOverlay} controls>
      <source src={placeHolder}></source>
    </video>
  );
};

export default ReactVideoPlayer;
