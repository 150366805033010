import "./Home.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Navigation, Autoplay } from "swiper";
import { Row, Col, Container } from "react-bootstrap";
import DefaultButton from "../../components/UI/Button/DefaultButton";
import Divider from "../../components/layout/divider/Divider";
import { PanelsData } from "../../products-data";
import CustomCard from "../../components/UI/Card/Card";
import CategoryCard from "../../components/UI/Card/CategoryCard";
import ReactVideoPlayer from "../../components/UI/VideoComponent/ReactVideoPlayer";
import Images from "../../Images";

const Home = () => {
  return (
    <>
      {/* Hero swiper */}
      <section>
        <Swiper
          className="hero-swiper hero-container"
          effect={"fade"}
          pagination={true}
          modules={[Pagination, EffectFade, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img src={Images.heroImg} className="img-fluid" alt="3d panels" />
            <div className="slide-content">
              <Container>
                <Row>
                  <Col>
                    <h1 className="text-uppercase ">3d panels collection</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DefaultButton
                      link="/products/3d-panels"
                      btnContent={"more detail"}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              rel="preload"
              src={Images.heroImg_2}
              className="img-fluid"
              alt="3d panels"
            />
            <div className="slide-content">
              <Container>
                <Row>
                  <Col>
                    <h1 className="text-uppercase ">flex paneli</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DefaultButton
                      link="/products/flex-panels"
                      btnContent={"more detail"}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      {/* divider */}
      <Divider dividerContent="new in" />

      {/* render products */}
      <section>
        <Container>
          <Swiper
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            // navigation={{
            //   nextEl: ".image-swiper-button-next",
            //   prevEl: ".image-swiper-button-prev",
            //   disabledClass: "swiper-button-disabled",
            // }}
            className="mySwiper new-slider"
            loop={true}
            slidesPerView={1}
            spaceBetween={50}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {PanelsData.map((items, id) => {
              return (
                <SwiperSlide key={id}>
                  <CustomCard
                    img={items.img}
                    altText={items.altText}
                    title={items.title}
                    description={items.descr}
                    price={items.price}
                    link={`/products/3d-panels/${items.link}`}
                  />
                </SwiperSlide>
              );
            })}
            {/* <i className="bi bi-arrow-left image-swiper-button-prev" />
            <i className="bi bi-arrow-right image-swiper-button-next" /> */}
          </Swiper>
        </Container>
      </section>

      {/* divider */}
      <Divider dividerContent="collection" />

      
      {/* categories */}
      <section>
        <Container className="collections-container">
          <Row>
            <Col xs={12} md={4}>
              <CategoryCard
                linkTo="products/flex-panels"
                category="flex panels"
                img={Images.categoryImage}
                altTag="marble panels"
              />
            </Col>
            <Col xs={12} md={4}>
              <CategoryCard
                linkTo="/products/3d-panels"
                category="3D panels"
                img={Images.categoryImage_2}
                altTag="wall panels"
              />
            </Col>
            <Col xs={12} md={4}>
              <CategoryCard
                linkTo="products/floors"
                category="floors"
                img={Images.categoryImage_3}
                altTag="floor panels"
              />
            </Col>
          </Row>
        </Container>
        <Container
          className="px-0 panels justify-content-md-between justify-content-center"
          fluid
        >
          <img
            src={Images.marblePanel}
            className="img-fluid d-lg-block d-none"
            alt="Marble panel"
          />
          <Row className="text-center justify-content-center">
            <Col md={7} xs={10} className="mb-4">
              <div className="h3-heading">
                Timeless modern walls, 3D panells and floors lorem ipsum dolor
                sit amet.
              </div>
            </Col>
            <Col md={7} xs={10}>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                pulvinar nisl massa, vitae condimentum elit posu ere imperdiet.
                Donec ornare imperdiet nisl, id finibus eros iaculis id. Nullam
                molTlis facilisis porttitor. Suspendisseledolor. Maecenas
                lobortis, nunc sed imperdiet suscipit.
              </p>
            </Col>
          </Row>
          <img
            src={Images.woodPanel}
            className="img-fluid d-lg-block d-none"
            alt="wood panel"
          ></img>
        </Container>
      </section>

      {/* divider */}
      <Divider dividerContent="installation tips" />

      {/* video carousel */}
      <section style={{ position: "relative" }}>
        <Container className="mb-3 video-carousel-container">
          <Swiper
            pagination={{ type: "fraction" }}
            modules={[Pagination, Navigation]}
            loop={true}
            navigation={{
              nextEl: ".image-swiper-button-next-vid",
              prevEl: ".image-swiper-button-prev-vid",
              disabledClass: "swiper-button-disabled",
            }}
            className="mySwiper video-swiper"
          >
            <SwiperSlide>
              <ReactVideoPlayer />
            </SwiperSlide>
            <SwiperSlide>
              <ReactVideoPlayer />
            </SwiperSlide>
            <SwiperSlide>
              <ReactVideoPlayer />
            </SwiperSlide>
            <SwiperSlide>
              <ReactVideoPlayer />
            </SwiperSlide>

            <i className="bi bi-arrow-left image-swiper-button-prev-vid" />
            <i className="bi bi-arrow-right image-swiper-button-next-vid" />
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default Home;
