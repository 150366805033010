import Images from "../../Images";
import Hero from "../../components/layout/hero/Hero";
import { Container, Row, Col } from "react-bootstrap";
import TeamCard from "../../components/UI/Card/TeamCard";

const About = () => {
  return (
    <>
      <Hero img={Images.aboutHero} altText="contact us" heading="o nama" />
      <section>
        <Container className="mt-5">
          <Row className="align-items-center mb-5">
            <Col md={6} xs={12} className="mb-md-0 mb-4">
              <Row>
                <Col className="mb-md-3 mb-2" xs={12}>
                  <h3>Naša priča</h3>
                </Col>
                <Col xs={10}>
                  <p>
                    PVC panele su sve popularniji izbor za uređenje zidova i
                    plafona u kućama, stanovima i poslovnim prostorima. PVC
                    (polivinil-hlorid) je materijal koji se često koristi za
                    izradu ovih panela, a oni predstavljaju ekonomično rešenje
                    mefsimfei za brzu i laku instalaciju, bez potrebe za
                    ozbiljnijim radovima.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={6} xs={12}>
              <img
                className="img-fluid"
                src={Images.aboutImg}
                alt="wall with a hanging frame"
              />
            </Col>
          </Row>
          <Row className="flex-column mb-5">
            <Col xs={12} className="mb-md-3 mb-2">
              <h3>Šta su PVC paneli?</h3>
            </Col>
            <Col lg={6} md={8} xs={12}>
              <p>
                PVC panele su sve popularniji izbor za uređenje zidova i plafona
                u kućama, stanovima i poslovnim prostorima. PVC
                (polivinil-hlorid) je materijal koji se često koristi za izradu
                ovih panela, a oni predstavljaju ekonomično rešenje za brzu i
                laku instalaciju, bez potrebe za ozbiljnijim radovima.
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs={12} className="mb-md-3 mb-2">
              <h3>Naš tim</h3>
            </Col>
            <Col className="mb-lg-0 mb-md-4 mb-0" lg={3} md={4} xs={12}>
              <TeamCard
                name="mitar radulovic"
                title="ceo"
                img={Images.ceoImg}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
