const Images = {
  heroImg: require("./assets/img/home-img/hero-bg.webp"),
  heroImg_2: require("./assets/img/home-img/hero-bg-2.webp"),
  panelsHeroImg: require("./assets/img/3d-panels-img/living-wall-bedroom-wood-wall-design.webp"),
  cardImg: require("./assets/img/3d-panels-img/card-img.png"),
  marblePanelBlue: require("./assets/img/flex-panels-img/blue.webp"),
  marblePanelCalca: require("./assets/img/flex-panels-img/calca.webp"),
  marblePanelCrema: require("./assets/img/flex-panels-img/crema.webp"),
  marblePanelEmperador: require("./assets/img/flex-panels-img/EMPERADOR.webp"),
  marblePanelGrey: require("./assets/img/flex-panels-img/grey.webp"),
  marblePanelLightGrey: require("./assets/img/flex-panels-img/light-grey.webp"),
  marblePanelNatura: require("./assets/img/flex-panels-img/natura.webp"),
  marblePanelPietraGrey: require("./assets/img/flex-panels-img/pietra-grey.webp"),
  marblePanelTraventino: require("./assets/img/flex-panels-img/TRAVENTINO.webp"),
  marblePanelWhite: require("./assets/img/flex-panels-img/white.webp"),
  marblePanelWood: require("./assets/img/flex-panels-img/wood.webp"),
  marblePanelWoodBlack: require("./assets/img/flex-panels-img/wood-and-black.webp"),
  partnerLogo_1: require("./assets/img/partners/flex-company-logo.png"),
  partnerLogo_2: require("./assets/img/partners/knoll-company-logo.png"),
  partnerLogo_3: require("./assets/img/partners/louis-poulsen-company-logo.png"),
  contactHeroImg: require("./assets/img/contact-us-img/bathroom.webp"),
  categoryImage: require("./assets/img/home-img/living-room-walls.webp"),
  categoryImage_2: require("./assets/img/home-img/bedroom-walls.webp"),
  categoryImage_3: require("./assets/img/home-img/bedroom-floors.webp"),
  videoOverlay: require("./assets/img/home-img/video-overlay.webp"),
  marblePanel: require("./assets/img/home-img/marble-panel.webp"),
  woodPanel: require("./assets/img/home-img/wood-panel.webp"),
  aboutHero: require("./assets/img/about-us/about-hero.webp"),
  aboutImg: require("./assets/img/about-us/wall-image-background.webp"),
  ceoImg: require("./assets/img/about-us/limun.png"),
  boxImg: require("./assets/img/post-img/box.png"),
  card: require("./assets/img/post-img/hipotekarna-banka-kartica-rate.png"),
  card_2: require("./assets/img/post-img/ckb-banka-kartica-rate.png"),
  card_3: require("./assets/img/post-img/nlb-banka-kartica-rate.png"),
};

export default Images;
