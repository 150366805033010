import "./CategoryCard.scss";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const CategoryCard = (props) => {
  return (
    <Card className="categoryCard">
      <Card.Img variant="top" src={props.img} alt={props.altTag} />
      <Link to={props.linkTo} className="stretched-link">
        <Card.Body>
          <Card.Title>{props.category}</Card.Title>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default CategoryCard;
