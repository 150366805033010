import Hero from "../../../components/layout/hero/Hero";
import Images from "../../../Images";
import { Container } from "react-bootstrap";
// import CustomCard from "../../../components/UI/Card/Card";
import "./Floors.scss";
// import { FloorPanelsData } from "../../../products-data";

const PanelsFloor = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <section>
        <Hero
          img={Images.heroImg_2}
          altText="Floor panels"
          heading="Floor panels"
        />
      </section>

      <section>
        <Container className="my-5 d-flex justify-content-center align-items-center">
          <h2 className="display-1 fw-bold color-main">Uskoro...</h2>
        </Container>
      </section>
    </>
  );
};

export default PanelsFloor;
